<template>
    <interior-page-content-container
        nav-selection=""
        @back-click="backNav"
        :show-back-button-top-left="true"
        :show-back-button-in-nav="true"
        :show-bottom-nav="projectPermissions !== 'basic'"
        :loading="loading"
        :showRightControls="true"
        backButtonText="Project"
    >
        <template #title>
          <div class="flex flex-col">
            <p>{{room.name}}</p>
            <p class="text-xs font-bold text-left text-content">{{room.floor}}</p>
          </div>
        </template>

        <template #right-action-buttons>
          <right-page-action-button
            v-for="rightActionButton in rightActionButtons"
            :key="rightActionButton.title"
            @click="$router.push(rightActionButton.route)"
            :active="false"
            :title="rightActionButton.title"
            :icon="rightActionButton.icon"
          />
        </template>

        <template #content>
          <div class="flex flex-col self-center h-full my-5 w-72 md:w-80 lg:w-96">

            <div class="`
              absolute top-0 w-72 md:w-80 lg:w-96 my-auto flex flex-col justify-center mx-auto
            `"
            >
              <div
                class="`
                  flex-grow-0 flex flex-row justify-center items-center
                  flex-shrink-0 pt-2 w-72 md:w-80 lg:w-96 bg-background
                `"
              >
                <search-bar
                  :value.sync="search"
                />
                <filter-component
                  @click="toggleFilter = !toggleFilter"
                  class="ml-2 cursor-pointer text-content"
                />
              </div>
              <div
                v-if="toggleFilter"
                class="`
                  flex flex-row flex-wrap justify-center pt-4 w-72 md:w-80 lg:w-96 bg-background
                `"
              >

                <div
                  v-for="key in Object.keys(itemTypes)" :key="key"
                  @click="itemTypes[key].selected = !itemTypes[key].selected"
                  class="`
                    flex flex-row items-center px-2 py-1 mx-1 mb-2
                    text-xs rounded-lg border border-contrast text-white cursor-pointer
                  `"
                  :class="
                    [itemTypes[key].selected
                    ? itemTypes[key].selectedColors : itemTypes[key].notSelectedColors]
                  "
                >
                  <moving :size="18" v-if="key === 'Moving'" />
                  <sale :size="18" v-if="key === 'Sale'" />
                  <storage :size="18" v-if="key === 'Storage'" />
                  <junk :size="18" v-if="key === 'Junk'" />
                  <donate :size="18" v-if="key === 'Donate'" />
                  <memory :size="18" v-if="key === 'Memory'" />
                </div>

              </div>
            </div>

            <div
              :class="[toggleFilter ? 'mt-20' : 'mt-10']"
              class="flex flex-col items-center w-full h-full pb-2"
            >
              <item-component
                v-for="(item, index) in filteredItems"
                @click="routeToItem(item.id)"
                :key="`item-${index}`"
                :name="item.name"
                :category="item.category"
                :image="item.picture.url"
              />

              <div
                v-if="room.items.length == 0"
                class="`
                  flex flex-col justify-end items-center flex-grow
                  px-16 font-bold text-center text-content
                `"
              >

                <div>
                  <p>
                    You currently do not have any items.
                    Click the button below to create your first!
                  </p>
                </div>

              </div>

              <p
                v-if="room.items.length > 0 && filteredItems.length === 0"
                class="mt-5 text-content"
              >
                No items match your query
              </p>

              <div class="flex flex-col justify-end flex-grow flex-shrink-0 pt-4 pb-10">

                <button-large
                  @click="createItem"
                  color="primary"
                  class="self-center w-32"
                >
                  New Item
                </button-large>
              </div>

            </div>

          </div>
        </template>

        <template #bottom-nav-center>
          <settings
            @click="settings"
            class="cursor-pointer settings"
            :size='37'
          />
        </template>
    </interior-page-content-container>
</template>

<script>
import Settings from 'vue-material-design-icons/Cog.vue';
import FilterComponent from 'vue-material-design-icons/Tune.vue';
import Moving from 'vue-material-design-icons/Truck.vue';
import Sale from 'vue-material-design-icons/Upload.vue';
import Storage from 'vue-material-design-icons/InboxArrowDown.vue';
import Junk from 'vue-material-design-icons/TrashCan.vue';
import Donate from 'vue-material-design-icons/TagHeart.vue';
import Memory from 'vue-material-design-icons/Star.vue';

import { mapRoomFields } from '@/store/mappers';
import { ROOM_GET } from '@/store/actions';
import { PROJECT_PERMISSIONS_CURRENT } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import ItemComponent from '@/components/projects/ItemComponent.vue';
import RightPageActionButton from '@/components/shared/inputs/RightPageActionButton.vue';
import ButtonLarge from '../../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'Room',
  components: {
    InteriorPageContentContainer,
    RightPageActionButton,
    Settings,
    SearchBar,
    ItemComponent,
    FilterComponent,
    Moving,
    Sale,
    Storage,
    Junk,
    Donate,
    Memory,
    ButtonLarge,
  },
  data() {
    return {
      loading: true,
      search: '',
      toggleFilter: false,
      itemTypes: {
        Memory: {
          notSelectedColors: 'text-yellow-400 bg-backfill',
          selectedColors: 'bg-yellow-400',
          selected: false,
          order: 1,
        },
        Moving: {
          notSelectedColors: 'text-blue-600 bg-backfill',
          selectedColors: 'bg-blue-600',
          selected: false,
          order: 2,
        },
        Storage: {
          notSelectedColors: 'text-pink-600 bg-backfill',
          selectedColors: 'bg-pink-600',
          selected: false,
          order: 3,
        },
        Sale: {
          notSelectedColors: 'text-green-600 bg-backfill',
          selectedColors: 'bg-green-600',
          selected: false,
          order: 4,
        },
        Donate: {
          notSelectedColors: 'text-purple-600 bg-backfill',
          selectedColors: 'bg-purple-600',
          selected: false,
          order: 5,
        },
        Junk: {
          notSelectedColors: 'text-red-600 bg-backfill',
          selectedColors: 'bg-red-600',
          selected: false,
          order: 6,
        },

      },
    };
  },
  computed: {
    ...mapRoomFields(['room', 'rooms']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    categoryFiltersActive() {
      return Object.keys(this.itemTypes)
        .filter((key) => this.itemTypes[key].selected).length > 0;
    },
    filteredItems() {
      const items = this.room.items.filter((item) => {
        const filterBasedOnCategory = this.categoryFiltersActive
          && this.toggleFilter
          && !this.itemTypes[item.category].selected;
        if (filterBasedOnCategory) return false;
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });

      return items.sort(
        (a, b) => this.itemTypes[a.category].order - this.itemTypes[b.category].order,
      );
    },
    rightActionButtons() {
      const buttons = [];
      if (this.projectPermissions !== 'basic') {
        buttons.push({
          title: 'Settings',
          icon: 'settings',
          route: {
            name: 'RoomSettings',
            params: {
              project_id: this.$route.params.project_id,
              room_id: this.$route.params.room_id,
            },
          },
        });
      }
      return buttons;
    },
  },
  created() {
    this.loading = !this.rooms[this.$route.params.room_id];
    this.room = this.rooms[this.$route.params.room_id] || {};
    this.$store.dispatch(ROOM_GET, {
      projectId: this.$route.params.project_id,
      roomId: this.$route.params.room_id,
    })
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'room' },
      }))
      .finally(() => { this.loading = false; });
  },
  methods: {
    backNav() {
      this.$router.push({
        name: 'Project',
        params: {
          project_id: this.$route.params.project_id,
          tab: 'Declutter',
        },
      });
    },
    createItem() {
      this.$router.push({
        name: 'ItemCreate',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      });
    },
    routeToItem(itemId) {
      this.$router.push({
        name: 'Item',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
          item_id: itemId,
        },
      });
    },
    settings() {
      this.$router.push({
        name: 'RoomSettings',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      });
    },
  },
};
</script>
